import styled from 'styled-components'
import cover from '../../assets/logos/Socials/coverphoto-opaque1.png'
import coverMobile from '../../assets/logos/Socials/coverphotoMobile.png'

export const LandingPageWrapper = styled.div`
    top: 0;
    margin: 0;
    padding: 0;
    display: fixed;
    height: 100vh;
    width: 100vw;
    background: url(${props => props.mobile ? coverMobile : cover}) center;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
`
