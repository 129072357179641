import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'

export const NavBarWrapper = styled.div`
    top: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    height: 30vh;
    width: 100vw;
    color: white;
    display: grid;
    grid-template-columns: ${props => props.mobile ? "grid-template-columns: repeat( 1, minmax(0, 1fr))" :  "repeat( 2, minmax(0, 1fr))" };
    align-content: start;
    font-family: kabel;
`

export const NavElement = styled.div`
    margin: ${props => props.mobile ? '0' : '5rem'};
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    align-self: start;
    width: ${props => props.mobile ? "auto" : "fit-content"};
    height: fit-content;
    background-color: ${props => props.mobile ? '#9AA6D3' : 'none'};
    border: ${props => props.mobile ? '.5rem solid white' : 'none'};
    font-family: kabel;
`

export const Title = styled.div`
    font-family: kabel;
    grid-column: 2; 
    align-self: center;
    font-size: 5vh;
    cursor: pointer;
    transition: .5s, 1s;
    :hover {
        opacty: .8;
        color: #282872;
        padding: auto;
        border-radius: 2%;
    }
`

export const Hamburger = styled(GiHamburgerMenu)`
    place-self: center;
    font-size: 5vh;
    cursor: pointer;
    transition: .5s, 1s;
    :hover {
        opacty: .8;
        color: #282872;
        padding: auto;
        border-radius: 5%;
    }
`

export const SideNav = styled.div`
    height: 100%;
    max-width: ${ props => props.open ? '100%' : '0'};
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #282872;
    overflow-x: hidden;
    dispay: grid;
    place-items: center;
    transition: ${ props => props.mobile ? '1s ease' : '2s ease'};
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    overflow: hidden;
`

export const CloseButton = styled.div`
    display: grid;
    color: #FFFFFF;
    align-items: center;
    justify-items: end;
    padding-right: .5vw;
    
    div {
        cursor: pointer;
        text-decoration: none;
        font-size: 3rem;
        transition: 0.3s;
    }
    div:hover {
        opacty: .8;
        color: black;
        padding: auto;
        border-radius: 5%;
    }
`

export const SideNavElement = styled.div`
    display: grid;
    place-items: center;
    margin: 2rem;
    font-family: kabel;
    a {
        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 2rem;
        transition: ease 0.3s;
        font-family: kabel;
    }
    a:hover {
        color: #9AA6D3;
        background: white;
        padding: auto;
        border-radius: 3%;
    }
`
