import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './components/App/App';
import Routes from './Routes';
import { createGlobalStyle } from 'styled-components';
import kabel from './assets/fonts/Kabel-Regular.ttf.woff'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: kabel;
        src: url(${kabel}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
`

const routes = (
    <Provider store={store}>
        <Router>
            <App>
                <GlobalStyle/>
                <Routes />
            </App>
        </Router>
    </Provider>
);

ReactDOM.render(routes, document.getElementById('root'));
