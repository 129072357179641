/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LandingPage from './components/LandingPage/LandingPage'

export default () => {
    return (
        <Switch>
            <Route
                component={LandingPage}
                exact
                path={'/'}
            />
        </Switch>
    )
}
