/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
    NavBarWrapper,
    NavBarMobileWrapper,
    Title,
    Hamburger,
    NavElement,
} from '../../styles/navbar/NavBarStyles'
import { useWindowDimensions } from '../../helpers/index'
import SideBar from './SideBar'

const NavBar = props => {
    const { navLinks, homeLink } = props
    const [sideBar, setSideBar ] = useState({ open: false })
    const { height, width } = useWindowDimensions()
    const mobile = width < 1200

    const openSideBar = () => { setSideBar({...sideBar, open: !sideBar.open})}

    return  (
        <NavBarWrapper mobile={mobile}>
            <NavElement mobile={mobile}>
                <Hamburger onClick={() => openSideBar()}/>
                <Title onClick={() => { window.location.replace(homeLink)}}> Blue Sage </Title>
                <SideBar 
                    {...sideBar}
                    openSideBar={openSideBar}
                    navLinks={navLinks}
                    mobile={mobile}
                />
            </NavElement>
        </NavBarWrapper>
    )
}

export default NavBar
