/* eslint-disable no-unused-vars */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { LandingPageWrapper } from '../../styles/landingPage/landingPage'
import { useWindowDimensions } from '../../helpers/index'

const MainView = props => {
    const { height, width } = useWindowDimensions()
    const mobile = width < 1200

    return (<LandingPageWrapper mobile={mobile} />)
}

export default withRouter(MainView)
